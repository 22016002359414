import Vue from 'vue';

import VueRouter from 'vue-router';

import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [{
    path: "/neonatal-unit",
    alias: "/",
    name: "home",
    component: Home
  },


  {
    path: "/epu",
    name: "epu",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: { requiresAuth: true },
    component: () =>
      import ( /* webpackChunkName: "epu" */ "../views/EPU.vue")
  },

  {
    path: "/otz",
    name: "otz",
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "otz" */ "../views/OTZ.vue")
  },

  {
    path: "/epupatients",
    name: "epupatients",
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "epupatients" */ "../views/EpupatsList.vue")
  },

  {
    path: "/otzpatients",
    name: "otzpatients",
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "otzpatients" */ "../views/OtzpatsList.vue")
  },

  {
    path: "/epu-newpatient",
    name: "epunewPat",
    meta: { requiresAuth: true },
    props: route => ({ editUser: route.params.editUser }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "epunewPat" */ "../views/Epunewpat.vue")
  },

  {
    path: "/otz-newpatient",
    props: route => ({ editUser: route.params.editUser }),
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "otznewPat" */ "../views/Otznewpat.vue"),
    children: [{
        path: "",
        alias: "caregiver",
        name: "caregiver",
        props: route => ({ editUser: route.params.editUser }),
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import ( /* webpackChunkName: "caregiver" */ "../views/Caregiver.vue")
      },
      {
        path: "client",
        name: "client",
        props: route => ({ editUser: route.params.editUser }),
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import ( /* webpackChunkName: "client" */ "../views/Client.vue")
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem('curUser')) || null
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user == null) {
      next({ name: "home", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;