<template>
  <div class="min-h-screen">
     <section class="relative min-h-screen flex justify-center items-center py-12 bg-white sm:py-16 lg:pt-20 lg:pb-96 xl:pt-24 xl:pb-72">
          <div class="absolute inset-0 bgg xs:hidden lg:block">
              <!-- <img class="object-cover  object-top w-full h-full" src="https://i.imgur.com/QscDh1V.jpg" alt="" /> -->
          </div>

          <div class="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div class="max-w-3xl mx-auto  text-center">
                  <h1 class="text-4xl font-semibold tracking-tight text-white sm:text-5xl xl:text-7xl sm:tracking-tight">Fethi Paediatrics</h1>
                  <p class="max-w-xl mx-auto mt-6 text-lg leading-7 text-white lg:leading-8 lg:text-xl">Trust us for personalized and attentive paediatric care that puts your child's needs first..</p>
                  <div class="flex flex-col items-center gap-5 mt-8">
                      <v-btn v-if="curUser"
                          @click="$router.push({path: '/epu'})"
                         color="black"
                          style="color: white !important;"
                          class="inline-flex cursor-pointer text-white items-center justify-center px-8 py-4 text-base font-medium  transition-all duration-200 bg-black border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                          role="button"
                      >
                          Dashboard
                      </v-btn>
                       <v-btn v-else
                            @click="dialog =!dialog"
                            title=""
                            color="black"
                            style="color: white !important;"
                            class="inline-flex text-white items-center justify-center px-8 cursor-pointer py-4 text-base font-medium  transition-all duration-200 bg-black border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                            role="button"
                        >
                            Login
                        </v-btn>

                     
                  </div>
              </div>

              <div class="-mt-4 sm:-mt-16 bgg md:-mt-32 xs:block lg:hidden">
                  <!-- <img class="object-cover bgg object-top w-full h-full" src="https://i.imgur.com/QscDh1V.jpg" alt="" /> -->
              </div>
          </div>
      </section>
    <!-- <div>
      <v-img :src="image" height="100vh">
        <div class=" mr-8">
          <v-row class=" justify-end mt-16">

            <v-btn @click="Epu('epu')" color="green lighten-3">
              EPU/CHOP
            </v-btn>
           
          </v-row>

          <v-row class=" justify-end mt-10">
           
            <v-btn v-if="false" @click="Epu('otz')" class=" d-block" color="green lighten-3">
              OTZ
            </v-btn>

          </v-row>
        </div>
      </v-img>
    </div> -->

    <v-dialog class="py-5 px-3" v-model="dialog" max-width="390">
      <v-card class="py-5 px-3">
        <h2 class="text-h5 text-center d-block">Login</h2>

        <v-form class="">
          <v-text-field class="inputs mt-2" label="Registration Number" v-model.trim="dform.reg_num"
            placeholder="Registration Number" outlined dense></v-text-field>

          <v-text-field class="inputs" label="Password" v-model.trim="dform.passwd" placeholder="Password" outlined
            dense></v-text-field>


        </v-form>

        <v-card-actions class="d-flex justify-center align-center">

          <v-btn @click="Login" :loading="loading">Login</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import axios from 'axios';
import { snackbar } from '@/main.js'
import { apiClient } from "@/services";
import { mapState } from 'vuex'
import { bus } from "@/main.js";
export default {
  name: "Home",
  components: {
    //  
  },
  data: () => ({
    dialog: false,
    dform: {
      reg_num: "",
      passwd: ""
    },
    currHosp: '',
    loading: false
  }),
  methods: {
    Epu(id) {
      if (this.curUser != null) {
        if (id == 'epu') {
          this.$router.push({ path: "/epu" });
        }
        if (id == 'otz') {
          this.$router.push({ path: "/otz" });
        }
      } else {
        this.dialog = true
        this.currHosp = id
      }


    },
    async Login() {
      this.loading = true;
      apiClient
        .post("/login", this.dform,
        )
        .then(res => {
          if (res.data.user_id) {
            this.$store.dispatch("CurUser", res.data.user_id)
            snackbar.$emit("displaySnackbar", { msg: "Logged In Succesfully", color: "success" });
            this.loading = false
            this.dialog = false
            this.$router.push({ path: "/epu" });

          } else {
            this.loading = false
            snackbar.$emit("displaySnackbar", { msg: "In Vallid User", color: "error" });

          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
          snackbar.$emit("displaySnackbar", { msg: "Error Eccoured", color: "error" });
        })
    }
  },
  created() {
    bus.$on("login", () => {
      this.dialog = !this.dialog

    });
  },
  computed: {
    ...mapState(["curUser"]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    image() {
      return this.mobile ? 'mbaby.jpeg' : 'paedy.jpg'
    }
  }
};
</script>

<style scoped>

.bgg{
  background: linear-gradient(rgba(0, 0, 0, 0.558), rgba(0, 0, 0, 0.493)), url(https://i.imgur.com/QscDh1V.jpg) !important;
  background-size: cover !important;
  background-position: center ;
  background-repeat: no-repeat !important;

}
.inputs {
  width: 100% !important;
}
</style>

