<template>
  <div class="d-block ma-4">
    <div class="mt-16 pt-16 text-center">

      <v-menu v-if="curUser" left open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2" v-bind="attrs" v-on="on" rounded text small depressed color="green darken-4">
            <v-icon>mdi-account-multiple</v-icon>
            PATIENTS
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(patient, i) in patients" :key="i" @click="() => { }" :to="patient.to">
            <v-list-item-title>{{ patient.title }}</v-list-item-title>
          </v-list-item>
        
        </v-list>
      </v-menu>

      <v-menu left open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="curUser" class="ma-2" v-bind="attrs" v-on="on" rounded text small depressed color="green darken-4">
            <v-icon>mdi-hospital</v-icon>
            NEW PATIENT
          </v-btn>
        </template>
        <v-list v-if="curUser">
          <v-list-item  v-for="(item, i) in items" :key="i" @click="() => { }" :color="item.color" :to="item.to">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider v-if="curUser" class="mt-5"></v-divider>

      <v-btn v-if="curUser" @click="LogOut" color="green darken-4" text><v-icon>mdi-export</v-icon> Logout</v-btn>
      <v-btn v-else @click="LogIn" color="green darken-4" text><v-icon>mdi-login</v-icon> LogIn</v-btn>

    </div>





  </div>
</template>

<script>

import { bus } from "@/main.js";
export default {
  name: 'NavDrawer',
  props: ['curUser'],

  data() {
    return {
      items: [
        { title: 'EPU NEW PATIENT', color: 'green', to: '/epu-newpatient' },
        { title: 'OTZ NEW PATIENT', color: 'green', to: '/otz-newpatient' },
      ],
      patients: [
        { title: 'EPU PATIENTs', to: '/epupatients' },
        { title: 'OTZ PATIENTs', to: '/otzpatients' },
      ],

    }
  },
  methods: {
    LogOut() {
      this.$store.dispatch("RemoveUser", null)
      this.$router.go({ path: '/' })
    },
     LogIn() {
      bus.$emit("login");
    },
  }

}
</script>