import Vue from 'vue';

import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    curUser: null,
    curPatient: {}
  },
  mutations: {
    curUser: (state, payload) => payload ? (state.curUser = {...payload }) : state.curUser = null,
    SET_CURRENT_PATIENT(state, payload) {
      localStorage.setItem("curPatient", JSON.stringify(payload.id));
      state.curPatient = payload;
    },

    SET_CURRENT_USER(state, payload) {
      localStorage.setItem("curUser", JSON.stringify(payload));
      state.curUser = payload;
    },
    REMOVE_CURRENT_USER(state, payload) {
      localStorage.removeItem("curUser");
      state.curUser = payload;
    },
  },
  actions: {
    CurPatient({ commit }, payload) {
      commit("SET_CURRENT_PATIENT", payload);
    },
    CurUser({commit}, payload){
      commit("SET_CURRENT_USER",payload )
    },
    RemoveUser({ commit }, payload) {
      commit("REMOVE_CURRENT_USER", payload)
    }

  },
  modules: {}
});