import './plugins/axios';
import './registerServiceWorker';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';

import VueExcelXlsx from 'vue-excel-xlsx';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

export const snackbar = new Vue();
Vue.use(snackbar)

Vue.use(VueExcelXlsx);
export const bus = new Vue();
Vue.config.productionTip = false;

new Vue({
  
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
