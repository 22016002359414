<template>
  <v-app>
    <v-app-bar app class="green lighten-5">
      <router-link to="/">
        <v-img alt="Vuetify Name" class="shrink mt-1 rounded-circle" contain min-width="50" src="logo.jpg"
          max-width="50"></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <div v-show="!$vuetify.breakpoint.xsOnly">






        <v-btn v-if="curUser" class="mr-1" :to="{ path: '/epupatients' }" rounded text small outlined depressed color="green darken-4">
          <v-icon>mdi-account-multiple</v-icon>
          PATIENTS
        </v-btn>



        <v-btn v-if="curUser" class="mr-1" :to="{ path: '/epu-newpatient' }" rounded text small outlined depressed color="green darken-4">
          <v-icon>mdi-hospital</v-icon>
          NEW PATIENT
        </v-btn>
        <v-btn v-if="curUser" class="mr-1" @click="LogOut" rounded text small outlined depressed color="green darken-4">
          <v-icon>mdi-signout</v-icon>
          LogOut
        </v-btn>
        <v-btn v-else class="mr-1" @click="LogIn" rounded text small outlined depressed color="green darken-4">
            <v-icon>mdi-signout</v-icon>
            Login
          </v-btn>


      </div>
      <v-app-bar-nav-icon v-show="$vuetify.breakpoint.xsOnly" @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>



    <v-navigation-drawer v-model="drawer" app temporary hide-overlay right>
      <NavDrawer :curUser="curUser" />
    </v-navigation-drawer>
    <v-snackbar :color="snackcolor" v-model="snackbar" :timeout="3">
      {{ snackmsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <router-view></router-view>
    </v-main>


  </v-app>
</template>

<script>
import { snackbar } from '@/main.js';
import { mapState } from 'vuex'
import NavDrawer from './components/NavDrawer.vue';

import { bus } from "@/main.js";
export default {
  name: "App",

  components: {
    NavDrawer
  },
  mounted() {
    this.checkUser(),
      snackbar.$on("displaySnackbar", (event) => {
        this.snackcolor = event.color
        this.snackbar = true;
        this.snackmsg = event.msg

      });
  },

  data: () => ({
    drawer: false,
    items: [
      { title: 'EPU NEW PATIENT', color: 'green', to: '/epu-newpatient' },
      // { title: 'OTZ NEW PATIENT', color: 'green', to: '/otz-newpatient' },
    ],
    patients: [
      { title: 'EPU/CHOP PATIENTs', color: 'green', to: '/epupatients' },
      // { title: 'OTZ PATIENTs', color: 'green', to: '/otzpatients' },
    ],
    snackbar: false,
    snackmsg: '',
    snackcolor: '',

  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    ...mapState(["curUser"]),
  },

  watch: {
    mobile(newval) {
      if (!newval) {
        this.hideDrawer()
      }
    }
  },
  methods: {
    LogOut() {
      this.$store.dispatch("RemoveUser", null)
      this.$router.go({ path: '/' })
    },
    LogIn() {
       bus.$emit("login");
    },
    hideDrawer() {
      this.drawer = false
    },
    checkUser() {
      let user = JSON.parse(localStorage.getItem('curUser')) || null
      if (user != null) {
        this.$store.dispatch("CurUser", user)
      }
    }
  },

};

</script>

